import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from './views/dashboard';
import EmployeeDetails from './views/employeeDetails'
import { UserContext } from "./UserProvider";
import React from "react";
import Login from "./views/login";
import Signup from "./views/Signup";
import NotFound from "./views/NotFound";
import EmployeeRequested from './views/EmployeeRequested';

const RoutesHandler = () => {

    const { user } = React.useContext(UserContext);

    let routes = [];


    if (!('token' in user)) {
        routes.push(<Route key="login" path="*" element={<Login />} />);
        routes.push(<Route key="signup" path="signup" element={<Signup />} />);
    }

    else {

        if (user.role === 0) { // for admin
            routes.push(<Route key="dashboard" index element={<Dashboard />} />);
            routes.push(<Route key="employee" path="/Employee/:id" element={<EmployeeDetails />} />);
            routes.push(<Route key="employee/Requested" path="/Employee/Requested" element={<EmployeeRequested />} />);
        }

        if (user.role === 1) { // for employee

        }


        if (user.role === 2) { // for lead

        }


        if (user.role === 3) { // for sale

        }

        routes.push(<Route key="404" path="*" element={<NotFound />} />);
    }

    return (
        <BrowserRouter>
            <Routes>
                {routes}
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesHandler
