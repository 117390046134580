/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { UserContext } from '../UserProvider';
import { useParams } from "react-router-dom";
import Header from '../components/header';
import '../css/employeeDetails.css';
import Loading from './loading';
import axios from 'axios'


export function CardViews({ isBusy, data, title, round }) {
    return (
        <div className='col-12 col-sm-6 col-md-6 col-lg-4 p-3'>
            <div className="card">
                <div className="card-header">{title}</div>
                {isBusy ?
                    <div className='p-2 text-center'>
                        <div className="spinner-border text-gray" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div> :
                    <div className="card-body">
                        <p className="card-text">
                            {
                                !round ? data : Math.round(data)
                            }
                        </p>
                    </div>
                }
            </div>
        </div>
    );
}

export function AccountViews({ reload }) {

    const { user, logout } = React.useContext(UserContext);
    const [isBusy, setBusy] = React.useState(true);
    const hour = useRef(null);
    const param = useParams();
    const [data, setData] = useState([0, 1, 2, 3, 4, 5]);

    async function fetchData() {
        try {
            const { data } = await axios.post(`https://developers.aetsmsoft.com/api/DashboardHandler.php`, JSON.stringify({ token: user.token, id: param.id, type: 'FETCH_ACC_TRACKER_DETAILS' }));

            // console.log(data)
            if (!data.success) {

                if (data.error === 'INVALID_USER') {
                    logout();
                }
                console.log(data.error)

                return;
            }
            setData(data.data)
            setBusy(false);

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => { fetchData(); }, [reload]);

    return (
        <div className='d-flex flex-wrap' ref={hour}>
            {data.map((result, index) => (
                <CardViews key={index} isBusy={isBusy} data={result.data} title={result.title} round={result.round} />
            ))}
        </div>
    );
}

export function ImagesViews({ data, setReload }) {

    const { user, logout } = React.useContext(UserContext);


    function getTime(timestamp) {
        const dateObj = new Date(timestamp);

        const options = {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };
        return dateObj.toLocaleString("en-US", options);
    }

    const handleAction = async (e) => {
        const element = e.target;
        // console.log(e.target)
        if (element.classList.contains('overlay')) {
            let data = e.target.parentNode.getAttribute('data');
            let link = data.includes('https') ? data.replace('https://postimg.cc', "https://i.postimg.cc") + '/image.jpg' : ('https://developers.aetsmsoft.com/Assets/images/Tracker/' + data);
            window.open(link, "_blank");
        }
        // if (element.classList.contains('a-open')) {
        //     let data = e.target.getAttribute('data');
        //     let link = data.includes('https') ? data : ('https://developers.aetsmsoft.com/Assets/images/Tracker/' + data);
        //     window.open(link, "_blank");
        // }

        if (element.classList.contains('a-delete')) {
            const element = e.target;
            const id = e.target.getAttribute('data');
            const confirmed = window.confirm("Are you sure you want to delete this?");
            element.style.display = 'none'

            if (confirmed) {
                try {
                    const { data } = await axios.post(`https://developers.aetsmsoft.com/api/DashboardHandler.php`, JSON.stringify({ token: user.token, id: id, type: 'DELETE_FROM_TRACKER' }));

                    if (!data.success) {
                        if (data.error === 'INVALID_USER') {
                            logout();
                        }
                        return;
                    }

                    setReload(pre => pre + 1);
                    e.target.closest('.overlay').classList.add('deleted');

                } catch (error) {
                    console.log(error)
                }
            }
            element.style.display = 'flex'
        }
    }

    return (
        JSON.parse(data).map((data, index) => (
            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 p-3' key={index}>
                <div className="card tracker-img" onClick={(e) => handleAction(e)} data={data.path}>
                    {data.path.includes('https') ?
                        // <a href={data.path} target='_blank' rel="noreferrer">
                        <>
                            <img className='showLoader' src={data.path.replace('https://postimg.cc', "https://i.postimg.cc") + '/image.jpg'} border='0' alt='screen-shot' style={{ borderRadius: '7px', height: '200px', width: '100%' }} onLoad={(event) => { event.currentTarget.classList.remove('showLoader'); }} />
                            <div className='p-2 text-center d-flex justify-content-center align-items-center Loader' style={{ height: '200px' }}>
                                <div className="spinner-border" style={{ color: 'gray' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </>
                        // </a>
                        :
                        // <a href={'https://developers.aetsmsoft.com/Assets/images/Tracker/' + data.path} target='_blank' rel="noreferrer">
                        <>
                            <img src={'https://developers.aetsmsoft.com/Assets/images/Tracker/' + data.path} className="card-img-top showLoader" alt="screen-shot" style={{ borderRadius: '7px', height: '200px', width: '100%' }} onLoad={(event) => { event.currentTarget.classList.remove('showLoader'); }} />
                            <div className='p-2 text-center d-flex justify-content-center align-items-center Loader' style={{ height: '200px' }}>
                                <div className="spinner-border" style={{ color: 'gray' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </>
                        // </a>
                    }
                    <div className={`overlay ${data.deleted === 'true' ? 'deleted' : ''}`}>
                        <button className='btn a-delete' data={data.id}><span className="material-icons a-delete" data={data.id}>delete</span></button>
                        <h6 className='text-muted'>Deleted</h6>

                        {/* <div className="dropdown">
                            <button className="btn btn-outline-dark text-light border-0 " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="material-icons">settings</span>
                            </button>
                            <ul className="dropdown-menu">
                                <li><button className='dropdown-item a-open' data={data.path}>View</button></li>
                                <li><button className='dropdown-item a-delete' data={data.id}>Delete</button></li>
                            </ul>
                        </div> */}

                    </div>
                </div>
                <div className='ps-2'>{getTime(data.time)}</div>
            </div>
        ))
    )
}

export function DetailsViews({ data, setReload }) {

    function getTime(time) {
        const hours = Math.floor(time);
        const minutes = Math.round((time - hours) * 60);
        const roundedMinutes = Math.floor(minutes / 10) * 10;
        return `${hours} hours and ${roundedMinutes} minutes`;
    }

    return (
        data?.map((data, index) => (
            <div key={index} className="mt-5 pt-3">
                <div className='d-flex justify-content-between px-3 flex-wrap'>
                    <h5>{data.created_at}</h5>
                    <h6>{getTime(data.total_hours)}</h6>
                </div>
                <div className='d-flex flex-wrap'>
                    <ImagesViews data={data.images} setReload={setReload} />
                </div>
            </div>
        ))
    )
}

export async function FetchMoreData(currentPage, param, user, logout, updateData) {
    try {
        const { data } = await axios.post(`https://developers.aetsmsoft.com/api/DashboardHandler.php`, JSON.stringify({ token: user.token, id: param.id, page: currentPage, type: 'FETCH_MORE_TRACKER_DETAILS' }));

        if (!data.success) {

            if (data.error === 'INVALID_USER') {
                logout();
            }

            console.log(data.error)
            return false;
        }

        updateData(data.data);
        return true;

    } catch (error) {
        console.log(error)
        return false;
    }
}

export async function FetchDetails(user, logout, param, setBusy, setData, setPage, setMore) {
    try {
        const { data } = await axios.post(`https://developers.aetsmsoft.com/api/DashboardHandler.php`, JSON.stringify({ token: user.token, id: param.id, type: 'FETCH_TRACKER_DETAILS' }));

        if (!data.success) {

            if (data.error === 'INVALID_USER') {
                logout();
            }

            if (data.error === 'DATA_NOT_FOUND') {
                setBusy(false);
            }
            return;
        }

        // console.log(data)

        setData(pre => {
            setPage(data.event);
            if (data.event.totalPages > 1) setMore(true);
            return data.data

        });

        setBusy(false);

    } catch (error) {
        console.log(error)
    }
}

export function LoadMore({ FetchMore, isMore }) {

    const handleScroll = async () => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

        if ((scrollTop + clientHeight) + 60 >= scrollHeight) {
            FetchMore();
        }
    };

    useEffect(() => {
        if (isMore) {
            window.addEventListener('scroll', handleScroll);
        }
        return () => window.removeEventListener('scroll', handleScroll);

    }, []);

    if (isMore) {
        return (
            <div className='d-flex my-4 justify-content-center align-items-center gap-3'>

                <div className="spinner-border text-gray" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <div className='p-3 text-center fw-bolder fs-5'>. . .</div>
    )

}

export function UserProfile() {


    const { user, logout } = React.useContext(UserContext);
    const [isBusy, setBusy] = React.useState(true);
    const param = useParams();
    const [data, setData] = useState([]);


    async function fetchData() {
        try {
            const { data } = await axios.post(`https://developers.aetsmsoft.com/api/DashboardHandler.php`, JSON.stringify({ token: user.token, id: param.id, type: 'FETCH_USER_WITH_ID' }));

            // console.log(data)
            if (!data.success) {

                if (data.error === 'INVALID_USER') {
                    logout();
                }
                console.log(data.error)

                return;
            }
            setData(data.data)
            setBusy(false);

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => { fetchData() }, []);


    if (isBusy) {
        return (
            <div className='p-2 text-center h-100 d-flex justify-content-center align-items-center card'>
                <div className="spinner-border text-gray" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }


    return (
        <div className="card">
            <div className='text-center p-3 pb-2'>
                <img src={'https://developers.aetsmsoft.com/Assets/images/profile/' + data.image} alt="profile" height={'150px'} width={'150px'} onLoad={(event) => { event.currentTarget.classList.remove('showLoader'); }} className="rounded-circle showLoader" />
                <div className='p-2 text-center d-flex justify-content-center align-items-center Loader'>
                    <div className="spinner-border text-gray" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <p className="card-text"><strong>{data.FullName}</strong> </p>
            </div>
        </div>
    )
}

export default function Initialize() {

    const { user, logout } = React.useContext(UserContext);
    const [isBusy, setBusy] = React.useState(true);
    const [page, setPage] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [reload, setReload] = React.useState(0);
    const [isMore, setMore] = React.useState(false);
    const param = useParams();

    useEffect(() => {
        FetchDetails(user, logout, param, setBusy, setData, setPage, setMore)
    }, []);

    let isWait;
    let currentPage = 1;

    function updateData(result) {
        setData(preData => {

            let newData = [];
            for (let index = 0; index < preData.length; index++) {
                let data = preData[index];
                newData.push(data);
            }

            for (let index = 0; index < result.length; index++) {
                let data = result[index];
                newData.push(data);
            }

            return newData;

        });
        isWait = false;
    }

    const FetchMore = async () => {

        if (isWait) return false;
        isWait = true;

        const totalPage = page.totalPages;
        if (currentPage + 1 > totalPage) return setMore(false);

        currentPage = currentPage + 1;
        const ok = await FetchMoreData(currentPage, param, user, logout, updateData);
        if (!ok) { currentPage = currentPage - 1; isWait = false }
    }

    if (isBusy) {
        return <Loading display="Loading" />
    }

    return (
        <div className='container'>
            <Header />
            <div className='d-flex flex-wrap-reverse mt-5'>
                <div className='col-12 col-md-12 col-lg-8 col-xl-9'>
                    <AccountViews reload={reload} />
                </div>
                <div className='col p-3'>
                    <UserProfile />
                </div>
            </div>
            <div className='mt-5'>
                <DetailsViews data={data} setReload={setReload} />
            </div>
            <LoadMore isMore={isMore} FetchMore={FetchMore} />
        </div>
    )
}