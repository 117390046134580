import axios from "axios";

class Auth {

    static user = '';
    static token = '';
    static error;

    static Signup = async (payload) => {

        // FullName: mustafa
        // image: (binary)
        // Email: mustafa@gmail.com
        // Password: darkssj123

        try {
            const { data } = await axios.post('https://developers.aetsmsoft.com/api/LoginHandler?type=Signup', payload, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              });

            if (Number(data.status)) {
                this.token = data.token;
                return true
            }

            this.error = data.Reason;
            return false;

        } catch (error) {
            this.error = error;
            return false;
        }
    }

    static login = async (credentials) => {
        try {
            const { data } = await axios.post('https://developers.aetsmsoft.com/api/DashboardHandler', JSON.stringify(credentials));

            console.log(data)

            if (data.success) {
                this.token = data.token;
                return true
            }

            this.error = data.error;
            return false;

        } catch (error) {
            this.error = error;
            return false;
        }
    }

    static session = async (token) => {
        try {
            const { data } = await axios.post('https://developers.aetsmsoft.com/api/DashboardHandler', JSON.stringify(token));

            if (data.success) {
                this.user = data.user;
                return true
            }

            this.error = data.error;
            return false;

        } catch (error) {
            this.error = error;
            return false;
        }

    }



}

export default Auth;