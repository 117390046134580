import React from 'react';
import Header from '../components/header';
import axios from 'axios';
import '../css/empReq.css';
import { UserContext } from '../UserProvider';



const FETCH_REQ_EMPLOYEE = async (user, setUsers, setBusy, logout) => {
    try {
        const { data } = await axios.post(`https://developers.aetsmsoft.com/api/DashboardHandler`, JSON.stringify({ token: user.token, type: 'FETCH_REQ_EMPLOYEE' }));

        if (!data.success) {
            if (data.error === 'INVALID_USER') {
                logout();
            }
        }

        setUsers(data.data);
        setBusy(false);

    } catch (error) {
        console.log(error)

    }
}

const EmployeeList = () => {

    const [isBusy, setBusy] = React.useState(true);
    const [users, setUsers] = React.useState([]);
    const { user, logout } = React.useContext(UserContext);


    React.useEffect(() => {
        FETCH_REQ_EMPLOYEE(user, setUsers, setBusy, logout);
    }, []);

    if (isBusy) {
        return (
            <div className='p-3 text-center'>
                <div className="spinner-border text-gray" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        users.map((user, index) => (
            <tr key={index}>
                <td>
                    <div className="d-flex pe-5">
                        <img src={`https://developers.aetsmsoft.com/Assets/images/profile/${user.profile}`} height="50px" width="50px" style={{ borderRadius: '50px' }} alt='profile' />
                        <div className="ps-3"> <h5 className="font-weight-bold mb-0">{user.name}</h5>
                            <p>{user.email}</p>
                        </div>
                    </div>
                </td>
                <td>
                    <div className='d-flex justify-content-end gap-2 p-2'>
                        <button className='btn btn-outline-primary' data={user.id}>Approve</button>
                        <button className='btn btn-outline-primary' data={user.id}>Delete</button>
                    </div>
                </td>
            </tr>
        ))
    )
}

const EmployeeRequested = () => {
    return (
        <div className='container'>
            <Header />
            <div className='d-flex justify-content-center mt-3'>
                <div className='mt-5 card col-12 col-md-12 col-lg-10 col-xl-8'>
                    <div className='card-header'>
                        <div className='card-title'>Employee Requested</div>
                    </div>
                    <div className='card-body table-responsive'>
                        <table className="table table-borderless table-hover mb-0">
                            <tbody>
                                <EmployeeList />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeRequested
