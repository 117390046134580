/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react'
import { useNavigate } from "react-router-dom";
import { UserContext } from '../UserProvider';

const Header = () => {

    const navigate = useNavigate();
    const { user, logout } = React.useContext(UserContext);

    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <img src='https://aetsmsoft.com/wp-content/uploads/2023/01/YU-01-e1674994488741-150x57.png' style={{ objectFit: 'contain' }} className='py-2' alt='logo' />
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        {/* <li className="nav-item">
                            <button className="nav-link" aria-current="page" onClick={() => navigate('/')}>
                                Employees
                            </button>
                        </li> */}
                        <li className="nav-item dropdown">
                            <button className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Employees
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><button className='dropdown-item' onClick={() => navigate('/')}>All Employees</button></li>
                                {/* <li><button className='dropdown-item' onClick={() => navigate('/Employee/Requested')}>Request</button></li> */}
                                {/* <li><hr className="dropdown-divider" /></li> */}
                                {/* <li><button className="dropdown-item" onClick={() => logout()}>Logout</button></li> */}
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <button className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {user.name}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><button className='dropdown-item'>Settings</button></li>
                                <li><button className='dropdown-item'>Report</button></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><button className="dropdown-item" onClick={() => logout()}>Logout</button></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header
