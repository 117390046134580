import React from 'react'
import axios from 'axios';
import '../css/dashboard.css';
import Loading from '../views/loading';
// import Users from '../components/users';
import errorImg from '../asset/errorImg.png'
import Header from '../components/header';
import { UserContext } from '../UserProvider';
import { useNavigate } from "react-router-dom";


const FetchUsers = async (user, logout, setUsers, setBusy) => {
    try {
        const { data } = await axios.post(`https://developers.aetsmsoft.com/api/DashboardHandler`, JSON.stringify({ token: user.token, type: 'FETCH_EMPLOYEE' }));

        if (!data.success) {
            if (data.error === 'INVALID_USER') {
                logout();
            }
        }

        setUsers(data.data);
        setBusy(false);

    } catch (error) {
        console.log(error)

    }
}

export function Users({ users, navigate }) {
    return (
        users.map((user, index) => (
            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 p-3' onClick={() => navigate('/Employee/' + user.id)} key={index} style={user.id === 30 || user.id === 10 ? { order: '-1' } : {}}>
                <div className="card h-100" key={index}>
                    <div className='d-flex justify-content-center align-items-center' style={{minHeight: '250px', maxHeight: '250px'}}>
                        <img src={'https://developers.aetsmsoft.com/Assets/images/profile/' + user.image} className="showLoader card-img-top" alt="profile" height='100%' style={{ objectFit: 'fill', filter: 'brightness(0.7)', padding: '12px', borderRadius: '17px' }} onError={(event)=> {event.currentTarget.src = errorImg; event.currentTarget.style = 'height : 30px;width:auto' }} onLoad={(event) => { event.currentTarget.classList.remove('showLoader'); }} />
                        <div className='p-2 text-center d-flex justify-content-center align-items-center Loader'>
                            <div className="spinner-border" style={{ color: 'gray' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body py-0">
                        <h5 className="card-text mb-3">{user.name}</h5>
                    </div>
                </div>
            </div>
        ))
    )
}

const Dashboard = () => {

    const [isBusy, setBusy] = React.useState(true);
    const [users, setUsers] = React.useState([]);
    const { user, logout } = React.useContext(UserContext);

    const navigate = useNavigate();

    React.useEffect(() => {
        FetchUsers(user, logout, setUsers, setBusy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (isBusy) {
        return (
            <div className='d-flex justify-content-center w-100'>
                <Loading display="Loading" />
            </div>
        )
    }

    return (
        <div className="container">
            <Header />
            <div className='d-flex flex-wrap py-4 mt-2'>
                <Users users={users} navigate={navigate} />
            </div>
            <div className='border-bottom text-center p-1 mb-2'>version 3.1.0</div>
        </div>
    )
}

export default Dashboard
