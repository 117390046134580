import React from 'react'
import Auth from '../Auth';
import '../css/login.css'
import { UserContext } from '../UserProvider';
import { useNavigate } from "react-router-dom";


const Login = () => {

    const [data, setData] = React.useState({ email: '', password: '', remember: false });
    const [error, setError] = React.useState({ code: '', msg: '' });
    const { setUser } = React.useContext(UserContext);
    const [Onbusy, setOnbusy] = React.useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value })
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (Onbusy === true) {
            return false;
        }

        setOnbusy(true);

        const { email, password } = data;

        const payload = {
            email: email,
            password: password,
            type: 'USER_LOGIN'
        }

        if (await Auth.login(payload)) {
            const user = JSON.stringify({ token: Auth.token });
            localStorage.setItem('user', user);
            return setUser(JSON.parse(user));
        }

        setOnbusy(false);

        if (Auth.error === 'INVALID_USER') {
            return setError({ msg: 'Incorrect email or password' });
        }

        return setError({ msg: 'Try Again' });
    }


    return (
        <main className='d-flex justify-content-center align-items-center vh-100 w-100 flex-column'>
            <img src='https://aetsmsoft.com/wp-content/uploads/2023/01/YU-01-e1674994488741-150x57.png' style={{ position: 'absolute', left: '30px', top: '20px' }} alt='logo' />
            <span className='text-muted' onClick={() => navigate('/signup')} style={{ position: 'absolute', right: '30px', top: '20px' }}>Signup</span>


            <form className='d-flex flex-column gap-3' style={{ minWidth: '320px' }} onSubmit={handleSubmit} >
                <div className='text-light'>
                    <h4 className='mb-1'>Welcome !</h4>
                    <h6>Sign Into Your Account</h6>
                </div>
                <input className='form-control' name='email' type="email" placeholder='Email' value={data.email} onChange={handleChange} required />
                <input className='form-control' name='password' type="password" placeholder='Password' value={data.password} onChange={handleChange} required />
                <button className='btn btn-primary mt-2' disabled={Onbusy} >
                    {Onbusy === false ? 'Login' : 'Please wait ...'}
                </button>
                <p className='text-danger m-0 p-0 d-inline-block'>{error.msg}</p>
            </form>
        </main>
    )
}

export default Login