import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import './css/icon.css';
import UserProvider from './UserProvider'
import reportWebVitals from './reportWebVitals';
import RoutesHandler from './Routes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <UserProvider >
    <RoutesHandler />
  </UserProvider >
  // </React.StrictMode>
);
reportWebVitals();
