import React from 'react'

const Loading = (props) => {
    return (
        <div className='vh-100 d-flex justify-content-center align-items-center gap-3'>
            <div className="spinner-border text-gray" role="status">
                <span className="visually-hidden">Loading...</span>
            </div> <h6 className='mb-0'>{props.display} ...</h6>
        </div>
    )
}

export default Loading
