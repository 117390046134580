import React from 'react'
import image from '../asset/404.png';

const NotFound = () => {
    return (
        <div className='d-flex align-items-center justify-content-center vh-100'>
            <img src={image} height="100px" width="auto" alt='error' style={{ filter: 'invert(0.6)' }} />
        </div>
    )
}

export default NotFound
