import React from 'react'
import Auth from '../Auth';
import '../css/login.css'
// import { UserContext } from '../UserProvider';
import { useNavigate } from "react-router-dom";
import Avatar from '../asset/add2.png';

const Signup = () => {

    const [data, setData] = React.useState({ FullName: '', Email: '', Password: '' });
    const [error, setError] = React.useState({ code: '', msg: '' });
    // const { setUser } = React.useContext(UserContext);
    const [Onbusy, setOnbusy] = React.useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value })
    };

    const handleImage = (e) => {
        const file = e.target.files[0];

        if (e.target.files.length === 0) {
            return;
        }

        const imageBox = e.target.parentNode.querySelector('img');

        // Validate file extension
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if (!allowedExtensions.test(file.name)) {
            alert('Invalid file extension. Please select an image file.');
            return false;
        }

        // Validate MIME type
        var allowedMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (!allowedMimeTypes.includes(file.type)) {
            alert('Invalid file type. Please select an image file.');
            return false;
        }

        // Create an Object URL from the Blob
        imageBox.src = URL.createObjectURL(new Blob([file], { type: file.type }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (Onbusy === true) {
            return false;
        }

        setOnbusy(true);

        // const { name, email, password } = data;

        // const payload = {...data}


        const payload = new FormData(event.target);

        // console.log(payload.has('image'))

        if (await Auth.Signup(payload)) {
            return navigate('/')
            // const user = JSON.stringify({ token: Auth.token });
            // localStorage.setItem('user', user);
            // return setUser(JSON.parse(user));
        };

        setOnbusy(false);

        // if (Auth.error) {
        return setError({ msg: Auth.error });
        // }

        // return setError({ msg: 'Try Again' });
    }

    return (
        <main className='d-flex justify-content-center align-items-center vh-100 w-100 flex-column'>
            <img src='https://aetsmsoft.com/wp-content/uploads/2023/01/YU-01-e1674994488741-150x57.png' style={{ position: 'absolute', left: '30px', top: '20px' }} alt='logo' />
            <span className='text-muted' onClick={() => navigate('/')} style={{ position: 'absolute', right: '30px', top: '20px' }}>Login</span>


            <form className='d-flex flex-column gap-3' method='POST' style={{ minWidth: '320px' }} onSubmit={handleSubmit} >
                <div className='text-light'>
                    {/* <h4 className='mb-1'>Sign Up !</h4> */}
                    {/* <h6>Sign Into Your Account</h6> */}
                </div>
                <div className='text-center'>
                    <label htmlFor="profile" className='position-relative'>
                        <img src={Avatar} className='rounded-circle border' height='120px' width="120px" alt='profile' />
                        <input type="file" onChange={handleImage} className='opacity-0 position-absolute top-50 start-50'
                            style={{ width: '1px' }} name='image' id='profile' required />
                    </label>
                </div>
                <input className='form-control' name='FullName' type="text" placeholder='Name' value={data.FullName} onChange={handleChange} required autoComplete='off' />
                <input className='form-control' name='Email' type="email" placeholder='Email' value={data.Email} onChange={handleChange} required autoComplete='off' />
                <input className='form-control' name='Password' type="password" placeholder='Password' value={data.Password} onChange={handleChange} required />
                <button className='btn btn-primary mt-2' disabled={Onbusy} >
                    {Onbusy === false ? 'Signup' : 'Please wait ...'}
                </button>
                <p className='text-danger m-0 p-0 d-inline-block'>{error.msg}</p>
            </form>
        </main>
    )
}

export default Signup
