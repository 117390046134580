import React from 'react';
import Loading from './views/loading';
import Auth from './Auth';

// Create a context for user data
export const UserContext = React.createContext({});

export default function UserProvider({children}) {

    const hasToken = localStorage.hasOwnProperty('user') ? true : false

    const [user, setUser] = React.useState(
        hasToken ? JSON.parse(localStorage.user) : {}
    );

    const Authorizing = async () => {
        if (!(await Auth.session({ token: user.token, type: 'USER_AUTH' }))) {
            localStorage.removeItem('user');
            setUser({});
            return false
        }
        setUser({ token: user.token, id: Number(Auth.user.idx), email: Auth.user.email, name: Auth.user.name, role: Number(Auth.user.role) })
        return true;
    }

    if (('token' in user) && !('email' in user)) {
        Authorizing();
        return <Loading display="Authorizing" />
    }

    function logout() {
        setUser({});
        localStorage.removeItem('user');
    }

    return (
        <UserContext.Provider value={{ user, setUser, logout }}>
            {children}
        </UserContext.Provider>
    );
}